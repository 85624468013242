import React from 'react'
import './Join.css'
import emailjs from '@emailjs/browser' 
import { useRef } from 'react'

const Join = () => {
    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_5huuckk', 'template_wz226kr', form.current, 'YpeGAXq8Ob7WqbyDXA')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };

  return (
    <div className='Join' id="join-us">
        <div className='left-j'>
            <hr />
        <div>
            <span className='stroke-text'>READY TO</span>
            <span>LEVEL UP</span>
        </div>
        <div>
            <span>YOUR BODY</span>
            <span className='stroke-text'>WITH US?</span>
        </div>
        </div>
        <div className='right-j'></div>
        <form action='' className='email-container' onSubmit={sendEmail}>
            <input type='email' name='user-email' placeholder='Enter Your Email Address'/>
            <button className='btn btn-j'>Join Now</button>
            </form>

        
    </div>
  )
}

export default Join